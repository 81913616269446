<template>
<div>
  <form ref="loginFrom" @submit.prevent="login">
    <VueInput
      label="Login"
      :id="username"
      name="username"
      :error="usernameError"
      v-model="username"
      autocomplete="username"
    />
    <VueInput
      label="Password"
      name="password"
      type="password"
      :error="passwordError"
      v-model="password"
      autocomplete="current-password"
    />
    <div class="d-flex justify-content-end">
      <div class="text-secondary" @click="$router.push({name: 'resetPassword'})">Forgot password?</div>
    </div>
    <p v-if="errors" class="text-danger text-center mt-3">
      {{ errors }}
    </p>
    <VueButton
      cls="main"
      class="w-100 mt-4 fw-500 mb-5"
    >
      Sign In
      <span v-if="loading" class="spinner-border spinner-border-sm ms-3" role="status"></span>
    </VueButton>
    </form>
  <hr class="my-4 text-secondary"/>
  <div class="d-flex justify-content-center">Not a member?</div>
  <VueButton
    class="w-100 my-2"
    @click="$router.push({name: 'signup'})"
  >Create an Account
  </VueButton>
  <div class="p-3">
      <div @click="$router.push({name: 'accountVerification'})" class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer">
        <div class="d-flex align-items-center justify-content-center">
          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('@/assets/img/account/security-user.svg')+')'}"></div>
          <div class="fs-14"> Verify Account </div>
        </div><img alt="icon" :src="require('@/assets/to-right-arrow.svg')">
      </div>
      <div @click="$router.push({name: 'reactivateProfile'})" class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer">
        <div class="d-flex align-items-center justify-content-center">
          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('@/assets/img/account/arrow-counterclockwise.svg')+')'}"></div>
          <div class="fs-14"> Reactivate Account </div>
        </div><img alt="icon" :src="require('@/assets/to-right-arrow.svg')">
      </div>
      <!-- <div @click="$router.push({name: 'responsibleGambling'})" class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer">
        <div class="d-flex align-items-center justify-content-center">
          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('@/assets/img/account/dice-5.svg')+')'}"></div>
          <div class="fs-14"> Responsible Gambling </div>
        </div><img alt="icon" :src="require('@/assets/to-right-arrow.svg')">
      </div> -->
  </div>

  <vue-modal ref="askBiometric">
  <template v-slot:header>
      <div class="p-3 bg-dark">
        <div class="auth-logo bg-dark" :style="{backgroundImage: 'url('+require('@/assets/logo.png')+')'}"></div>
      </div>
  </template>
  <template v-slot:default>
    <div class="mt-4">Do you want to use biometrics (finger print or face) to login?</div>
    <div class="mt-3 d-flex">
      <vue-button @click="_cancelBiometricAuth" class="me-1">No</vue-button>
      <vue-button cls="main" @click="_confirmBiometricAuth" class="ms-1">Yes</vue-button>
    </div>
  </template>
  </vue-modal>
</div>
</template>

<script>
import VueModal from "@/components/UI/VueModal";
import VueButton from "@/components/UI/VueButton";
import VueInput from "@/components/UI/VueInput";
import {useToast} from "vue-toastification";
import {mapActions} from "vuex";
import {NativeBiometric} from "capacitor-native-biometric";
import {nextTick} from "vue";

export default {
  name: "LogIn",
  components: {VueModal, VueButton, VueInput},
  data: ()=>{
    return{
      username: '',
      usernameError: '',
      password: '',
      passwordError: '',
      errors: '',
      loading: false,
      loginTries: 3,
      resolveAskBiometricAuth: null,
      rejectAskBiometricAuth: null,
    }
  },
  setup(){
    const toast = useToast()
    return {toast}
  },
  watch:{
    loading(val){
      if (val) {
        this.$emit('start-loading')
      } else {
        this.$emit('finish-loading')
      }
    }
  },
  mounted(){
    if (this.$store.state.authToken) this.$router.push({name: 'home'})
    if (['ios', 'android'].includes(this.$store.state.platform)) this.nativeLogin()
    nextTick(()=>document.getElementById('username').focus())
  },
  methods:{
    ...mapActions(['SET_AUTH_TOKEN', 'SET_USER_DATA', 'LOGIN']),
    nativeLogin(){
      const vm = this
      const useBiometricLogin = JSON.parse(localStorage.getItem('useBiometricLogin'))
      NativeBiometric.isAvailable().then(isAvailable=>{
        if (isAvailable && useBiometricLogin){
          NativeBiometric.getCredentials({server: 'geoffbanks.bet'})
            .then(credentials=>{
               NativeBiometric.verifyIdentity({
                  reason: `Login to ${vm.APPINFO.appName}`,
                  title: `Login to ${vm.APPINFO.appName}`,
                  subtitle: `${vm.loginTries} tries left.`
                })
              .then(()=>{
                vm.loading = true
                return vm.LOGIN({username: credentials.username, password: credentials.password})
                .then(()=>vm.$router.push({name: 'home'}))
                .catch((err)=>{
                  vm.toast.error(err)
                  NativeBiometric.deleteCredentials({server: "geoffbanks.bet"})
              })
                .finally(()=>vm.loading=false)
              })
              .catch((error)=>{
                if (error.code === "10" && vm.loginTries>0){
                  vm.loginTries -= 1
                  vm.nativeLogin()
                } else {
                  vm.$store.dispatch('LOGOUT').finally(() => {
                    vm.userDataLoading = false
                    return false
                  })
                }
            })
          })
        }
      })
    },
    nativeSaveCredentials(){
      const vm = this
      return new Promise((resolve, reject)=>{
        NativeBiometric.setCredentials({
            username: vm.username,
            password: vm.password,
            server: "geoffbanks.bet",
          }).then(()=>resolve(), (error)=>reject(error))
      })
    },
    askNativeLoginConfirmation(){
      const vm = this
      vm.$refs.askBiometric.open()
      return new Promise((resolve, reject)=>{
          vm.resolveAskBiometricAuth = resolve
          vm.rejectAskBiometricAuth = reject
      })
    },
    _confirmBiometricAuth(){
      const vm = this
      vm.nativeSaveCredentials()
      .then(
          ()=>{
            console.log('>>> SAVED CREDS')
            NativeBiometric.getCredentials({server:'geoffbanks.bet'})
            .then(creds=>console.log('>>>>>####>>>', creds))
            localStorage.setItem('useBiometricLogin', 'true')
            vm.resolveAskBiometricAuth(true)
          },
          (error)=>{
            console.log('Native Save Credentials Error', error)
            vm.rejectAskBiometricAuth(false)
          }
      )
      .finally(()=> vm.$refs.askBiometric.close())

    },
    _cancelBiometricAuth(){
      localStorage.setItem('useBiometricLogin', 'false')
      this.$refs.askBiometric.close()
      this.rejectAskBiometricAuth(false)
    },
    login(){
      const vm = this
      if (vm.loading) return
      this.usernameError = ''
      this.passwordError = ''
      if (!this.username) this.usernameError = 'Username is required!'
      if (!this.password) this.passwordError = 'Password is required!'
      if (this.username && this.password){
        vm.loading = true
        this.LOGIN({username:vm.username, password:vm.password})
          .then(()=>{
            const useBiometricAuth = JSON.parse(localStorage.getItem('useBiometricLogin'))
            if (vm.$store.state.platform !== "web" && useBiometricAuth === null) {
              vm.askNativeLoginConfirmation().then(()=>vm.finishLogin())
            } else {
              vm.finishLogin()
            }
          })
          .catch((error)=> {
            vm.toast.error(error.toString())
            vm.$store.dispatch('LOGOUT')
          })
          .finally(()=>vm.loading = false)
      }
    },
    finishLogin(){
       if (this.$route.query.from){
         this.$router.push(this.$route.query.from)
       } else {
         this.$router.push({name: 'home'})
       }
       if (!this.$store.state.isMobile){
         this.$emit('close-modal')
       }
    }
  }
}
</script>

<style scoped>
.auth-logo{
  height: 2rem;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>