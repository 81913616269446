<template>
<div v-if="!checked" class="geo-check bg-dark d-flex justify-content-center align-items-center p-2 opacity-75">
  <div class="text-white me-2">Checking access in your location</div>
  <div class="spinner-grow spinner-grow-sm text-warning" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
<vue-modal dark no-close="true" ref="blockApp">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <div class="failed-picture" :style="{backgroundImage: 'url('+require('@/assets/img/warningIcon.png')+')'}"></div>
      <div class="text-center text-danger pb-4"><span>{{blockMessage}}</span></div>
      <vue-button cls="main" @click="closeApp">Close App</vue-button>
    </div>
  </vue-modal>
</template>

<script>
import {Geolocation} from "@capacitor/geolocation";
import {Device} from "@capacitor/device";
import VueModal from "@/components/UI/VueModal";
import VueButton from "@/components/UI/VueButton";
import {App} from "@capacitor/app";

export default {
  name: "GeoAccess",
  emits: ['checked'],
  components: {VueButton, VueModal},
  data:()=>{
    return{
      blockMessage: '',
      checked: true
    }
  },
  mounted() {
    this.startCheck()
    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) this.startCheck()
    })
  },
  beforeUnmount() {
    App.addListener('appStateChange', ({ isActive }) => {
      console.log('App state changed. Is active?', isActive);
    });
  },
  methods:{
    startCheck(){
      const vm = this
      if (vm.$refs.blockApp) vm.$refs.blockApp.close()
      vm.geoAccess()
        .then(()=>{
          console.log('Country is OK!')
          vm.$emit('checked')
          if (vm.$refs.blockApp) vm.$refs.blockApp.close()
        })
        .catch((error)=>{
          vm.blockMessage = error
          vm.$refs.blockApp.open()

        })
        .finally(()=>{
          vm.checked = true
        })
    },
    geoAccess(){
      const vm = this
      vm.blockMessage = null
      return new Promise((resolve, reject)=>{
        vm.checkPlatform().then((platform)=>{
           vm.checked = false
           Geolocation.checkPermissions()
          .then(data=>{
            if (data.coarseLocation !== 'granted' || data.location !== 'granted'  ) {
              setTimeout(()=>{
                Geolocation.requestPermissions().then((perm)=>console.log('>>>> requestPermissions', JSON.stringify(perm)))
              }, 2000)
              reject('Allow location access in the app settings!')
            } else {
              vm.checkAccess(platform)
                .then(()=>resolve())
                .catch(error=>reject(error))
            }
          })
          .catch(()=> {
            Geolocation.getCurrentPosition().finally(()=>reject('Relaunch app please.'))
          })
        })
        .catch(()=>resolve())
      })
    },
    checkPlatform(){
      return new Promise((resolve, reject)=> {
        Device.getInfo()
            .then(info => {
              if (info.platform !== 'web') {
                resolve(info.platform)
              } else reject()
            })
      })
    },
    async checkAccess(platform){
      const vm = this
      const coordinates = await Geolocation.getCurrentPosition()
      return new Promise((resolve, reject)=>{
        vm.API.AllowedCountries(platform)
            .then(countries=>{
              vm.API.geocoding(coordinates.coords.latitude, coordinates.coords.longitude)
              .then(geocode=>{
                const currentCountry = geocode.results[0].components.country
                console.log('>>>>>>> %$%$%$ >>>>> ', currentCountry,  countries.filter(c=>c.includes(currentCountry)).length)
                countries.push('Russia')
                if (countries.filter(c=>c.includes(currentCountry)).length){
                  console.log(`${currentCountry} is OK`)
                  resolve()
                } else reject(`${vm.APPINFO.appName} App is not allowed in ${currentCountry}!`)
              })
            })
          })
    },
    permissionRequest(){
      Geolocation.getCurrentPosition()
    },
    closeApp(){
      try{
        if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
          App.exitApp()
        }
        if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
          navigator.app.exitApp();
        }
      } catch (error) {
        console.log(error)
      }
    },
  }
}
</script>

<style scoped>
.geo-check{
  position: absolute;
  bottom: 5rem;
  left: 50%;
  min-width: 10rem;
  width: max-content;
  transform: translate(-50%, 0);
  border-radius: 10px;
}
.failed-picture{
  height: 20vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 1rem 0 2rem;
}
</style>