<template>
<div class="betslip__header px-3 rounded-top" :class="{'betslip__header-descktop': !$store.state.isMobile}" >
      <div class="fw-600" :class="{'text-white fs-13': $store.state.isMobile}">
        <div v-if="betSlipStatus==='success'" class="d-flex align-items-center"><img class="me-2" :src="require('@/assets/img/check-circle.svg')" alt="success"/><span> Bet Placed</span></div>
        <div v-else-if="betSlipStatus==='failure'" class="d-flex align-items-center"><img class="me-2" :src="require('../../assets/img/warning-triangle.svg')" alt="warning"/><span> Stake Failed</span></div>
        <div v-else><span>Bet Slip</span></div>
      </div>
      <div v-if="$store.state.isMobile" @click="$emit('close')" class="close-modal-btn cursor-pointer" >
        <img alt="arrow-down" :src="require('@/assets/img/arrow-down.svg')"/>
      </div>
    </div>
<div class="betslip__content rounded-bottom container-overflow-scroll">
  <div v-if="betSlipProcessing" class="d-flex align-items-center justify-content-center">
    <div class="m-5 p-5 d-flex justify-content-center align-items-center flex-column">
      <div style="height: 8rem; width: 8rem" class="position-relative"><pre-loader/></div>
      <div class="fs-20 fw-500 text-center">Wager processing with {{ APPINFO.appName }}.</div>
    </div>
  </div>
  
  <bet-slip-submit-response
    v-else-if="betslipSubmitResponse"
    :betslip-response="betslipSubmitResponse"
    @close="closeSubmitResponse"
    @updatestatus="updateBetSlipStatus"
    @init="loadBetslipInitial"
  />
  <div v-else-if="BETSLIP_ADDITIONAL_INFO.length" class="bets-wrapper">
    <bets-list class="flex-fill" :bets="BETSLIP_ADDITIONAL_INFO"/>
    <bet-slip-wager
        ref="wager"
        @close="$emit('close')"
        @betslipsubmitted="betSlipProcessing=true"
        @wager="keepWagerValues"
    />
  </div>
  <div v-else class="p-3">
    <div class="d-flex align-items-center justify-content-center flex-column mb-3">
      <div class="no-bets-icon mb-3">
        <!-- img/no-bets.svg -->
        <svg width="auto" height="auto" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="48" cy="48" r="48" fill="var(--geoff-icon-bg-3)"/>
          <g opacity="0.1">
            <path d="M68.55 49.375H60C58.975 49.375 58.125 48.525 58.125 47.5V30.025C58.125 28.175 58.85 26.45 60.15 25.15C61.45 23.85 63.175 23.125 65.025 23.125H65.05C68.175 23.15 71.125 24.375 73.375 26.6C75.625 28.875 76.85 31.875 76.85 35V41.05C76.875 46.025 73.525 49.375 68.55 49.375ZM61.875 45.625H68.55C71.45 45.625 73.125 43.95 73.125 41.05V35C73.125 32.85 72.275 30.8 70.75 29.25C69.225 27.75 67.175 26.9 65.05 26.875C65.05 26.875 65.05 26.875 65.025 26.875C64.2 26.875 63.4 27.2 62.8 27.8C62.2 28.4 61.875 29.175 61.875 30.025V45.625Z" fill="currentColor"/>
            <path d="M42.4994 78.325C41.3244 78.375 40.2244 77.875 39.3994 77.025L35.2494 72.85C35.0244 72.625 34.6744 72.6 34.4244 72.8L30.1494 76C28.8244 77 27.0744 77.175 25.5744 76.425C24.0744 75.675 23.1494 74.175 23.1494 72.5V35C23.1494 27.45 27.4744 23.125 35.0244 23.125H65.0244C66.0494 23.125 66.8994 23.975 66.8994 25C66.8994 26.025 66.0494 26.875 65.0244 26.875C63.2994 26.875 61.8994 28.275 61.8994 30V72.5C61.8994 74.175 60.9744 75.675 59.4744 76.425C57.9744 77.175 56.2244 77.025 54.8994 76.025L50.6244 72.825C50.3744 72.625 50.0244 72.675 49.8244 72.875L45.6244 77.075C44.7744 77.875 43.6744 78.325 42.4994 78.325ZM34.7744 68.925C35.9244 68.925 37.0494 69.35 37.8994 70.225L42.0494 74.4C42.1994 74.55 42.3994 74.575 42.4994 74.575C42.4994 74.525 42.7994 74.55 42.9494 74.4L47.1494 70.2C48.6994 68.65 51.1494 68.5 52.8744 69.825L57.1244 73C57.3994 73.2 57.6494 73.125 57.7744 73.05C57.8994 72.975 58.1244 72.825 58.1244 72.5V30C58.1244 28.875 58.3994 27.8 58.8744 26.875H34.9994C29.4494 26.875 26.8744 29.45 26.8744 35V72.5C26.8744 72.85 27.0994 73 27.2244 73.075C27.3744 73.15 27.6244 73.2 27.8744 73L32.1494 69.8C32.9244 69.225 33.8494 68.925 34.7744 68.925Z" fill="currentColor"/>
            <path d="M49.375 46.875H35.625C34.6 46.875 33.75 46.025 33.75 45C33.75 43.975 34.6 43.125 35.625 43.125H49.375C50.4 43.125 51.25 43.975 51.25 45C51.25 46.025 50.4 46.875 49.375 46.875Z" fill="currentColor"/>
            <path d="M42.5 53.75C41.475 53.75 40.625 52.9 40.625 51.875V38.125C40.625 37.1 41.475 36.25 42.5 36.25C43.525 36.25 44.375 37.1 44.375 38.125V51.875C44.375 52.9 43.525 53.75 42.5 53.75Z" fill="currentColor"/>
          </g>
        </svg>
      </div>
      <div class="fs-14 mb-2">Betslip empty</div>
      <div  class="fs-11 text-secondary mb-3">Select odds to add selection to slip</div>
    </div>
    <vue-button v-if="$store.state.isMobile" @click="$emit('close')" class="" cls="main">Ok</vue-button>
  </div>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {authApiService, betslipApi} from "@/services/betslip/_services";
import BetSlipWager from "@/components/BetSlip/BetSlipWager";
import BetsList from "@/components/BetSlip/BetsList";
import VueButton from "@/components/UI/VueButton";
import {signalRService} from "@/services/betslip/_services/signalrService";
import PreLoader from "@/components/UI/PreLoader";
import {BETSLIP_STATUSES} from "@/services/betslip/_constants";
import BetSlipSubmitResponse from "@/components/BetSlip/BetSlipSubmitResponse";
import {useToast} from "vue-toastification";

export default {
  name: "BetSlipMain",
  emits:['close'],
  components: {BetSlipSubmitResponse, PreLoader, BetSlipWager, BetsList, VueButton},
  data: function (){
    return {
      betslipSubmitResponse: null,
      // betslipSubmitResponse: {"id":"62a061a4113faf56809868b7","userId":"61c1f2950132a2e883d75039","status":2,"failureReason":null,"bets":[{"id":"62a994273947124ecc31ebf8","externalId":"2252255","eventFileName":"f6uBFTRP","eventGroup":1,"eventsNumber":0,"selectionNumber":0,"priceCode":"E","price":"8-11","gPriceCode":"K","gPrice":2,"coi":"000","detail":"Hell Red","name":"13:00 Uttoxeter","betPrice":"8-11","newPC":"EP","wagerName":"13:00 Uttoxeter","isAvailable":true,"isEachWay":true,"isBog":true,"oldPrice":null}],"wagers":[{"name":"Singles","typeId":13,"numberOfLines":1,"isEachWay":true,"eachWayType":"Each Way","eachWay":false,"stake":0,"toSubmit":false}],"wagersSubmitResult":[{"name":"Singles","typeId":13,"numberOfLines":1,"isEachWay":true,"eachWayType":"Each Way","eachWay":false,"stake":1,"isSubmitted":true,"potentialReturn":0.7273,"resultMessage":null,"ticketNumber":"1289928","stakePlaced":1,"isLiabilityExceeded":false,"liabilityExceededOn":null,"maxStakeAvailable":null,"customerReferenceNumber":null,"errorNumber":0,"isPriceChanged":false,"isEventEnded":false}],"message":null,"keepBetslip":false,"customerBalance":133.76,"isInsufficientFunds":false,"acceptPriceChanges":false},
      // betslipSubmitResponse: {"id":"62a061a4113faf56809868b7","userId":"61c1f2950132a2e883d75039","status":4,"failureReason":"Unexpected error occured","bets":[{"id":"62a8919d347018f90e8e2b19","externalId":"2250534","eventFileName":"e6uUnatl","eventGroup":17,"eventsNumber":0,"selectionNumber":0,"priceCode":"C","price":"17-20","gPriceCode":"C","gPrice":0,"coi":"000","detail":"Romania","name":"Romania v Montenegro","betPrice":"17-20","newPC":"CP","wagerName":"Romania v Montenegro","isAvailable":true,"isEachWay":false,"isBog":false,"oldPrice":null}],"wagers":[{"name":"Singles","typeId":13,"numberOfLines":1,"isEachWay":false,"eachWayType":"Win Only","eachWay":false,"stake":1,"toSubmit":false}],"wagersSubmitResult":[{"name":"Singles","typeId":13,"numberOfLines":1,"isEachWay":false,"eachWayType":"Win Only","eachWay":false,"stake":1,"isSubmitted":false,"potentialReturn":null,"resultMessage":null,"ticketNumber":null,"stakePlaced":null,"isLiabilityExceeded":false,"liabilityExceededOn":null,"maxStakeAvailable":null,"customerReferenceNumber":null,"errorNumber":-12,"isPriceChanged":false,"isEventEnded":true}],"message":null,"keepBetslip":false,"customerBalance":134.85,"isInsufficientFunds":false,"acceptPriceChanges":false},
      signalrConnection: null,
      betSlipProcessing: false,
      betSlipStatus: null,
      // betAmount: null
      wagerValues: null,
      intervalAdditionalInfoLoad: null,

    }
  },
  setup(){
    const toast = useToast()
    return { toast }
  },
  mounted(){
     if (this.$store.state.betslipStore.authToken) {
       this.setupSignalR()
     }
    this.loadBetslipInitial()
  },
  beforeUnmount(){
    if (this.intervalAdditionalInfoLoad) clearInterval(this.intervalAdditionalInfoLoad)
  },
  watch:{
    '$store.state.betslipStore.authToken'(authToken){
      if (authToken && !this.signalrConnection) {
        this.setupSignalR()
      }
    },
    'BETSLIP.bets': {
      handler(){
        this.reInitLoadAdditionaInfo() 
      },
      deep: true
    }
  },
  computed:{
    ...mapGetters(['BETSLIP', 'BETSLIP_ADDITIONAL_INFO'])
  },
  methods: {
    ...mapActions(['SET_BETSLIP_ID', 'SET_BETS_LENGTH', 'SET_BETSLIP', 'SET_BETSLIP_ADDITIONAL_INFO', 'UPDATE_USER_DATA']),
    setWagerForm(betslip, chosenWagerIndex){
      if (!this.$store.state.betslipStore.backupId) return
      const currWager = (betslip.wagers || [])[chosenWagerIndex]
      this.selectedWagerStake = currWager ? currWager.stake : 0
      this.selectedWagerEachway = currWager ? currWager.eachWay : false
    },
    loadBetslipInitial(){
      const vm = this
      let requestPromise = this.$store.state.betslipStore.backupId == null
          ? betslipApi(authApiService.logout)
              .createEmptyBetslip(vm.$store.state.authToken)
          : betslipApi(authApiService.logout)
              .getActiveBetslip(vm.$store.state.betslipStore.backupId, true);

      requestPromise
        .then((betslipResp => {
          if (betslipResp){
            vm.SET_BETSLIP_ID(betslipResp.id)
            vm.SET_BETSLIP(betslipResp)
            vm.setWagerForm(betslipResp, 0);
          }
        }))
        .catch(err => {
            console.error('request betslip', err);
        });
    },
    setupSignalR(){
      const connectionHubResp = signalRService.buildHub(
            this.$store.state.betslipStore.authToken,
            this.submitResponseHandler,
            authApiService.logout
        )
      if (connectionHubResp.isError) {
        console.error('signalR error: ' + connectionHubResp.error)
        this.signalrConnection = null
        this.$store.dispatch('LOGOUT')
      } else {
        console.info('add connection hub', connectionHubResp.connect)
      }
    },
    submitResponseHandler(betslipResp){
      const vm = this
      // DEBUG
      /*
      betslipResp['betsValidation'] = [
        {
          type:0,
          name:"Event Disabled",
          message1:"Following selections are no longer available:",
          message2:"Reload selection at current odds (insert odds).",
          betErrors:[
              {
                betId:"65e1bc0af17395828dcbd7da",
                betPrice:"2-1",
                betDetail:"Inspiritus",
                betName:"13:30 Lingfield",
                betPriceCode:"EP",
              }
          ]
        },
        {
          type:1,
          name:"Event Ended",
          message1:"Following selections are no longer available:",
          message2:"Reload selection at current odds (insert odds).",
          betErrors:[
              {
                betId:"65e1bc0af17395828dcbd7db",
                betPrice:"7-2",
                betDetail:"Soldierofthestorm",
                betName:"13:50 Newbury",
                betPriceCode:"EP",
              },
              {
                betId:"65e1bc0af17395828dcbd7db",
                betPrice:"7-2",
                betDetail:"Soldierofthestorm",
                betName:"13:50 Newbury",
                betPriceCode:"EP",
              },
              {
                betId:"65e1bc0af17395828dcbd7db",
                betPrice:"7-2",
                betDetail:"Soldierofthestorm",
                betName:"13:50 Newbury",
                betPriceCode:"EP",
              }
          ]
        },
        {
          type:3,
          name:"Selection Not Available",
          message1:"Following selections are no longer available:",
          message2:"Reload selection at current odds (insert odds).",
          betErrors:[
              {
                betId:"65e1bc0af17395828dcbd7dc",
                betPrice:"2-1",
                betDetail:"Cloud Cover",
                betName:"14:02 Lingfield",
                betPriceCode:"EP",
              }
          ]
        },
        {
          type:2,
          name:"Start Price Only",
          message1:"Price has changed to SP for the following selections:",
          message2:"Reload selection at current odds (insert odds).",
          betErrors:[
              {
                betId:"65e1bc0af17395828dcbd7dd",
                betPrice:"9-2",
                betDetail:"Bystander",
                betName:"13:30 Lingfield",
                betPriceCode:"EP",
              }
          ]
        },
        {
          type:4,
          name:"Price Changed",
          message1:"Price has changed for the following selections:",
          message2:"Please either reload selection at current odds (insert odds) or use the accept all price changes option.",
          betErrors:[
              {
                betId:"65e1bc0af17395828dcbd7de",
                betPrice:"5-1",
                betDetail:"Arcadian Nights",
                betName:"13:30 Lingfield",
                betPriceCode:"EP",
                betNewPrice:"6-1"
              },
              {
                betId:"65e1bc0af17395828dcbd7de",
                betPrice:"5-1",
                betDetail:"Arcadian Nights",
                betName:"13:30 Lingfield",
                betPriceCode:"EP",
                betNewPrice:"6-1"
              },
              {
                betId:"65e1bc0af17395828dcbd7de",
                betPrice:"5-1",
                betDetail:"Arcadian Nights",
                betName:"13:30 Lingfield",
                betPriceCode:"EP",
                betNewPrice:"6-1"
              }
          ]
        }
    ]
    */
    // END DEBUG

      console.log('SINGNALR RESPONSE', betslipResp)

      if (!betslipResp) return null
      if (betslipResp.bets.length > 0) vm.betslipSubmitResponse = betslipResp
      if (betslipResp.status === BETSLIP_STATUSES.SUCCEED) {
        betslipApi(vm.logout).createEmptyBetslip(vm.$store.state.authToken)
          .then(resp => {
              vm.SET_BETSLIP(resp)
              vm.UPDATE_USER_DATA()
              vm.betSlipProcessing = false
          })
          .catch(err => console.error(err))
      } else {
        vm.betSlipProcessing = false
      }
      return betslipResp
    },
    updateBetSlipStatus(status){
      this.betSlipStatus = status
    },
    closeSubmitResponse(data){
      // if (data?.limit) {
      //   this.betAmount = data?.limit
      // }
      
      this.betSlipStatus = null
      this.betslipSubmitResponse = null
      if(data.isResubmit) {
        this.$nextTick(() => {
          this.wagerValues.acceptPriceChanges = true
          this.$refs.wager.presetWager(this.wagerValues)
          this.$refs.wager.placeBet()
        })
      }
      else {
        this.$emit('close')
      }

      // Set previuser selected waget type and amount
      if (data?.isError) {
        // let stake = null
        if (data?.limit) {
          // stake = data?.limit
          this.wagerValues.betAmount = data.limit
        }
        this.$nextTick(() => {
          this.$refs.wager.presetWager(this.wagerValues)

        })
        // this.$refs.wager.presetWager()
      }
    },
    logout(){
      this.$store.dispatch('LOGOUT')
    },
    keepWagerValues(data){
      this.wagerValues = data
    },
    reInitLoadAdditionaInfo(requestImmediately = true) {
      if (requestImmediately) {
        this.loadAdditionalData()
      }
      clearInterval(this.intervalAdditionalInfoLoad);
      this.intervalAdditionalInfoLoad = setInterval(this.loadAdditionalData, 5000);
    },
    loadAdditionalData() {
      const model = this.BETSLIP.bets.map(el => {
        return {
          EventFile: el.eventFileName,
          EventGroup: el.eventGroup,
          EventIdx: el.eventsNumber
        }
      })
      if (model.length) {
        betslipApi(authApiService.logout).getBetslipAdditionslData(model)
          .then(resp => {
            this.SET_BETSLIP_ADDITIONAL_INFO(resp)
          })
      }
      
    }
  }
}
</script>

<style scoped>
.betslip__content_wrap{
  margin: 5rem 0;
}
.betslip__content{
  /* overflow-y: auto;
  overflow-x: auto; */
  /* position: relative; */
  box-shadow: 0px 24px 120px -30px rgba(0, 0, 0, 0.15);
  /* max-height: calc(100% - 3rem);
  min-height: 100%; */
  background-color: var(--geoff-bg-section);
  overflow: hidden;
  height: calc(100% - 2.5rem);
}
.betslip__header{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
  background: #09080C;
  border-radius: 0.75rem 0.75rem 0px 0px;
}
.betslip__header-descktop{
  /* background: #FFFFFF; */
  background: var(--geoff-bg-section);
  border-radius: 0.5rem 0.5rem 0 0;
}
.no-bets-icon{
  height: 3rem;
  width: 3rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.close-modal-btn{
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.close-modal-btn img{
  filter: brightness(100);
  width: 1rem;
  height: auto;
}

.bets-wrapper {
  max-height: 100%;
  display: flex;
  flex-direction: column;
}
.container-overflow-scroll {
  min-height: auto;
  overflow-y: scroll;
}
</style>