<template>
  <div class="wrap p-3">
    <div class="d-flex justify-content-between align-items-center pb-3">
      <div class="fw-600">Potential Return</div>
      <div class="fs-24 fw-600">£{{ profit }}</div>
    </div>
    <div v-if="BETSLIP.wagers.length" class="wager-type-wrapper">
      <div class="fs-14 d-flex justify-content-center align-items-center">{{ BETSLIP.wagers[wagerOptions.selectedWagerType].numberOfLines }} lines</div>
      <select class="wager-type-select flex-grow-1 text-center me-3 fs-20 fw-600 geoff-text-main" v-model="wagerOptions.selectedWagerType">
        <option class="" v-for="(wager, index) in BETSLIP.wagers" :selected="index==wagerOptions.selectedWagerType" :key="index" :value="index">{{wager.name}}</option>
      </select>

    </div>

    <div class="d-flex justify-content-between my-3">
      <div class="d-flex me-2">
        <vue-checkbox v-model="wagerOptions.retainSelectionsInBetslip"/>
        <div class="text-secondary fs-14 d-flex align-items-center ms-2">Retain Selections in Betslip</div>
      </div>
      <div class="d-flex ms-2">
        <vue-checkbox v-model="wagerOptions.acceptPriceChanges"/>
        <div class="text-secondary fs-14 d-flex align-items-center ms-2">Accept Any Price Changes</div>
      </div>
    </div>

    <div class="bet-total-wrapper position-relative" @click="showKeyboard=true">
      <div class="fs-14 d-flex justify-content-center align-items-center text-nowrap p-3">Bet amount</div>
      <div class="d-flex justify-content-center align-items-center position-absolute start-50 top-50 translate-middle">
        <div class="d-flex align-items-center" >
          <input
            v-if="!$store.state.isMobile"
            class="bet-total-input fs-20 fw-600 p-0"
            v-model="wagerOptions.betAmount"
            ref="betAmountInput"
            type="number"
            step="1"
            :style="{width: betInputWidth}"
            />
          <div v-else>
            <span class="fs-20 fw-600 me-1">£</span>
            <span class="fs-20 fw-600"> {{ wagerOptions.betAmount }}</span>
            <span v-if="showKeyboard" class="fs-20 fw-600 blink" style="margin-bottom: 3px">|</span>
          </div>
        </div>
      </div>
      <button v-if="BETSLIP.wagers[wagerOptions.selectedWagerType].isEachWay" @click="wagerOptions.eachWay=!wagerOptions.eachWay" class="bet-total-btn fs-14 d-flex align-items-center justify-content-center" :class="{'bet-total-btn-active': wagerOptions.eachWay}">
        <vue-checkbox name="eachWay" class="mx-1 p-0" v-model="wagerOptions.eachWay"/>
        Each way
      </button>
    </div>
    <div class="container-fluid mb-2">
      <div class="row px-2">
        <div 
          v-for="(stake, index) in boostStakes"
          :key="index"
          @click="wagerOptions.betAmount=(parseFloat(wagerOptions.betAmount)+stake).toString()"
          class="col-3 px-1 d-flex">
          <div class="w-100 my-1 stake-boost">{{ stake }}+</div>
        </div>
      </div>
    </div>
    <numeric-keyboard v-if="$store.state.isMobile" @backspace="backspace" @enter="enter" @confirm="showKeyboard=false" :show="showKeyboard"/>
    <vue-button v-if="$store.state.authToken" cls="main" class="w-100 fs-5 fw-600" @click="placeBet">Place Bet <span v-if="betTotal" class="fw-500 ms-2">£{{betTotal}}</span></vue-button>
    <vue-button v-else cls="main" class="w-100 fs-6 fw-500" @click="$router.push({name: 'login'})">Please Login/Signup to Place the Bet</vue-button>
  </div>
</template>

<script>
import VueCheckbox from "@/components/UI/VueCheckbox";
import {mapGetters} from "vuex";
import VueButton from "@/components/UI/VueButton";
import {getProfitMessage} from "@/services/betslip/_helpers";
import {authApiService, betslipApi} from "@/services/betslip/_services";
import NumericKeyboard from "@/components/BetSlip/NumericKeyboard";
import {useToast} from "vue-toastification";
export default {
  name: "BetSlipWager",
  emits: ['betslipsubmitted', 'wager'],
  components: {NumericKeyboard, VueButton, VueCheckbox},
  // props: {
  //   betAmount:{
  //     type: Number,
  //     default: null,
  //   }
  // },
  data:()=>{
    return{
      showKeyboard: false,
      wagerOptions:{
        eachWay: false,
        acceptPriceChanges: false,
        retainSelectionsInBetslip: false,
        betAmount: '0',
        selectedWagerType: 0,
      },
      betTotal: null,
      boostStakes: [5,10,15,50,100,125,150,200],
      profit: null,
    }
  },
  setup(){
    const toast = useToast()
    return { toast }
  },
  watch: {
    // 'BETSLIP.wagers'(){
    //   if(this.wagerOptions.selectedWagerType < (this.BETSLIP.wagers.length -1)) {
    //     this.wagerOptions.selectedWagerType = 0
    //   }
    // },
    '$store.state.betslipStore.betslip.bets'() {
      console.log('CHANGE BETS')
      this.betChanged()
      // this.profit = getProfitMessage(this.BETSLIP.bets, this.BETSLIP.wagers, this.wagerOptions.selectedWagerType, this.wagerOptions.betAmount, this.wagerOptions.eachWay)
      // this.betTotal = this.calculateTotalBet()
    },
    wagerOptions: {
      handler() {
        this.betChanged()
        const data = { ...this.wagerOptions }
        // console.log('DEBUG wagerOptions change', data)
        this.$emit('wager', data)
        // this.profit = getProfitMessage(this.BETSLIP.bets, this.BETSLIP.wagers, this.wagerOptions.selectedWagerType, this.wagerOptions.betAmount, this.wagerOptions.eachWay)
        // this.betTotal = this.calculateTotalBet()
      },
      deep: true
    },
    'wagerOptions.selectedWagerType'(){
      if(this.wagerOptions.eachWay && !this.BETSLIP.wagers[this.wagerOptions.selectedWagerType].isEachWay) {
        this.wagerOptions.eachWay = false
      }
    }
  },
  computed:{
    ...mapGetters(['BETSLIP', 'BETS_LENGTH']),
    betInputWidth(){
      return ((this.wagerOptions.betAmount.toString().length + 2) * 10) + 'px'
    }
  },
  // beforeMount() {
  //   if (this.betAmount) this.wagerOptions.betAmount = this.betAmount
  // },
  methods: {
    betChanged() {
      if (this.wagerOptions.selectedWagerType > (this.BETSLIP.wagers.length - 1)) {
        this.wagerOptions.selectedWagerType = 0
      }
      this.profit = getProfitMessage(this.BETSLIP.bets, this.BETSLIP.wagers, this.wagerOptions.selectedWagerType, this.wagerOptions.betAmount, this.wagerOptions.eachWay)
      this.betTotal = this.calculateTotalBet()
    },
    presetWager(wagerValues) {
      if (wagerValues) {
        this.wagerOptions = { ...wagerValues }
      }
      // if (wagerValues?.betAmount) {
      //   this.wagerOptions.betAmount = wagerValues.betAmount
      // }
      // if (wagerValues?.selectedWagerType) {
      //   this.wagerOptions.selectedWagerType = wagerValues.selectedWagerType
      // }
    },

    calculateTotalBet() {
      if (!this.wagerOptions.betAmount || !this.BETSLIP.wagers.length) return null
      let res = parseFloat(this.wagerOptions.betAmount)
      if (this.wagerOptions.eachWay) res=res*2
      if(this.BETSLIP.wagers[this.wagerOptions.selectedWagerType].numberOfLines) {
        res = this.BETSLIP.wagers[this.wagerOptions.selectedWagerType].numberOfLines * res
      }
      return res
    },
    setEachWay(){
      setTimeout(()=>this.wagerOptions.eachWay=!this.wagerOptions.eachWay, 100)
    },
    placeBet(){
      if (!Number(this.wagerOptions.betAmount)) {
        this.toast.warning('Enter the bet amount!')
        return
      }
      const betslip = {...this.BETSLIP}
      betslip.wagers[this.wagerOptions.selectedWagerType].stake = Number(this.wagerOptions.betAmount);
      betslip.wagers[this.wagerOptions.selectedWagerType].eachWay = this.wagerOptions.eachWay;
      betslip.wagers[this.wagerOptions.selectedWagerType].isEachWay = this.wagerOptions.eachWay;
      betslip.wagers[this.wagerOptions.selectedWagerType].toSubmit = true;

      const wagersWithProcessedStakes = betslip.wagers
        .map(wager => {
          wager.stake = !wager.stake ? 0 : wager.stake;
          return wager;
        });
      console.warn('SUBMITED BETSLIP DATA', betslip)
      if (!wagersWithProcessedStakes[this.wagerOptions.selectedWagerType].stake) {
        return;
      }

      const updatedBetslip = {...betslip};
      updatedBetslip.wagers = wagersWithProcessedStakes;
      updatedBetslip.keepBetslip = this.wagerOptions.retainSelectionsInBetslip;
      updatedBetslip.acceptPriceChanges = this.wagerOptions.acceptPriceChanges;
      console.warn('SUBMITED BETSLIP DATA 1', updatedBetslip)

      this.$emit('betslipsubmitted')
      betslipApi(authApiService.logout)
        .submitBetslipAsync(updatedBetslip, true)
        .catch(err => {
          console.error(err);
        });

    },
    enter(value){
      if (value!=='.'){
        this.wagerOptions.betAmount=parseFloat(this.wagerOptions.betAmount+=value).toString()
      } else {
        if (this.wagerOptions.betAmount.includes('.')) return
        this.wagerOptions.betAmount+=value
      }
    },
    backspace(){
      this.wagerOptions.betAmount = this.wagerOptions.betAmount.toString().slice(0,-1)
      if (!this.wagerOptions.betAmount) this.wagerOptions.betAmount = '0'
    }
  }
}
</script>

<style scoped>
.wrap{
  /*height: 20rem;*/
  border-radius: 0.75rem 0.75rem 0 0;
  background-color: var(--geoff-bg-main);
}
.bet-total-wrapper{
  display: flex;
  justify-content: space-between;
  height: 3.125rem;
  background: var(--geoff-bg-btn-1);
  border-radius: 4px;
  width: 100%;
}
.bet-total-input{
  width: 3.5rem;
  background: var(--geoff-bg-btn-1);
  border: none;
  overflow: hidden;
}
.bet-total-input:focus-visible,
.wager-type-select:focus-visible{
  outline: none;
}

.bet-total-btn{
  width: 6rem;
  text-align: center;
  color: #6D6D6D;
  background-color: var(--geoff-bg-btn-4);
  border-radius: 0 4px 4px 0;
  border: none;
}
.bet-total-btn-active{
  color: #000000;
  background-color: #FFD60A;
  transition: .1s;
}
.bet-total-btn:active{
  background-color: #debd16;
  transition: 0.1s;
}
.wager-type-wrapper{
  display: flex;
  justify-content: space-between;
  height: 3.125rem;
  background: var(--geoff-bg-btn-1);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  width: 100%;
}
.wager-type-select{
  height: 100%;
  background: var(--geoff-bg-btn-1);
  border-radius: 4px;
  border: none;
}
.stake-boost{
  /*color: rgba(9, 8, 12, 0.50);*/
  background: var(--geoff-bg-btn-1);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
}
.container-fluid{
  padding: 0.5rem 0 0;
}
.blink {
  -webkit-animation: blink .75s linear infinite;
  animation: blink .75s ease-in-out infinite;
}
@-webkit-keyframes blink {
  100% { color: rgba(34, 34, 34, 0); }
}
@keyframes blink {
  100% { color: rgba(34, 34, 34, 0); }
}
</style>