<template>
<div v-if="$store.state.isMobile" class="footer d-flex align-items-center flex-column text-center fs-12">
  <img class="mb-4" :src="require('@/assets/logo.png')"/>
  <div class="my-3 mx-4">© Copyright {{ new Date().getFullYear() }}</div>
  <hr />

  <!-- <div v-if="$store.state.platform==='web'" class="d-flex justify-content-around w-100">
    <div class="m-2"><a href="https://play.google.com/store/apps/details?id=com.geoff_banks.geoffbanks" target="_blank">
      <img src="@/assets/download_play.svg"/></a>
    </div>
    <div class="m-2"><a href="https://apps.apple.com/gb/app/geoff-banks-betting/id881898186" target="_blank">
      <img src="@/assets/download_apple.svg"/></a>
    </div>
  </div>
  <hr v-if="$store.state.platform==='web'"/> -->

  <div class="container">
    <div class="row">
      <!-- <div class="col-6 px-3 py-1 d-flex justify-content-center align-items-center"><a href="https://www.gamblingcommission.gov.uk/" class="footer_icons" ><img :src="require('@/assets/footer/gambling.png')" alt="gambling"/></a></div>
      <div class="col-6 px-3 py-1 d-flex justify-content-center align-items-center"><a href="https://www.gamstop.co.uk/" class="footer_icons" ><img :src="require('@/assets/footer/gamstop.png')" alt="gamstop"/></a></div>-->
      <div class="col-6 px-3 py-1 d-flex justify-content-center align-items-center"><a href="https://www.ibas-uk.com/" class="footer_icons" ><img :src="require('@/assets/footer/ibas.png')" alt="ibas"/></a></div> 
      <div @click="$router.push({name: 'info', params: {infoType: 'over18'}})" class="col-6 p-3 d-flex align-items-center justify-content-center">
        <div class="footer_icons icon_bg" :style="{backgroundImage: 'url(' + require('@/assets/footer/18+.png') + ')', minHeight: '2rem'}"></div>
      </div>
      <div class="col-12 p-3 d-flex align-items-center justify-content-center">
          <div id="anj-af7e1519-b1df-4775-9e6f-381b759e4931" data-anj-seal-id="af7e1519-b1df-4775-9e6f-381b759e4931" data-anj-image-size="128" data-anj-image-type="basic-small"></div>
        </div>
    </div>
  </div>
  <hr>

  <!-- <div class="container">
    <div class="row">
      <div class="col-6 px-3 py-1 d-flex justify-content-center align-items-center"><corner-tile media-name="blog" /></div>
      <div class="col-6 px-3 py-1 d-flex justify-content-center align-items-center"><corner-tile media-name="furlong"/></div>
    </div>
  </div> -->

  <hr>
  <div class="mt-3">
    sportbetsonline.bet  is owned and operated by Sports Betting Limited. Registration number: 15725, registered address: Hamchako, Mutsamudu, The Autonomous Island of Anjouan, Union of Comoros. Contact us at support@sportsbetting.com Sportbetsonline.bet is licensed and regulated by the Government of the Autonomous Island of Anjouan, Union of Comoros and operates under License No. ALSl-192407040-Fl2. Sportbetsonline.bet has passed all regulatory compliance and is legally authorized to conduct gaming operations for any and all games of chance and wagering.
  </div>
  <!-- <div class="mt-3">Responsible Gambling:<br>
    UK - 0808 8020 133 <a class="text-white opacity-50" href="https://www.begambleware.org" target="_blank">www.begambleware.org</a><br>
    IRL - 089 241 5401 <a class="text-white opacity-50" href="https://www.problemgambling.ie" target="_blank">www.problemgambling.ie</a>
  </div> -->
  
  <!-- <div class="mt-3">
    <a class="text-white" href="https://www.gamblingcommission.gov.uk/public-register/business/detail/54095" target="_blank">
      www.gamblingcommission.gov.uk/public-register/business/detail/54095</a>
  </div> -->
  <div class="mobile-footer-links mt-4">

    <a href="/docs/Sports Betting Limited Terms and Conditions.pdf" class="footer-link" target="_blank">Terms and Conditions</a>
    <a href="/docs/Responsible Gaming.pdf" class="footer-link" target="_blank">Responsible Gambling Policy</a>
    <a href="/docs/KYC Policy.pdf" class="footer-link" target="_blank">KYC Policy</a>
    <a href="/docs/HELP.pdf" class="footer-link" target="_blank">Help</a>
    <a href="/docs/CONTACT DETAILS.pdf" class="footer-link" target="_blank">Contact Details</a>

    <!-- <router-link class="footer-link" :to="{name:'info', params: { infoType: 'Terms And Conditions' }}">Terms & Conditions</router-link>
    <router-link class="footer-link" :to="{name:'info', params: { infoType: 'Privacy Policy' }}">Privacy Policy</router-link>

    <router-link class="footer-link" :to="{name:'info', params: { infoType: 'About Us' }}">About Us</router-link>
    <router-link class="footer-link" :to="{name:'info', params: { infoType: 'Contact Details' }}">Contact Details</router-link>

    <router-link class="footer-link" :to="{name:'info', params: { infoType: 'Betting Rules' }}">Betting Rules</router-link>
    <router-link class="footer-link" :to="{name:'info', params: { infoType: 'Best Odds Guaranteed' }}">Best Odds Guaranteed</router-link>

    <router-link class="footer-link" :to="{name:'info', params: { infoType: 'Cookie Policy' }}">Cookie Policy</router-link>
    <router-link class="footer-link" :to="{name:'info', params: { infoType: 'ResponsibleGambling' }}">Responsible Gambling</router-link>

    <router-link class="footer-link" :to="{name:'info', params: { infoType: 'Help' }}">Help</router-link>
    <router-link class="footer-link" :to="{name:'info', params: { infoType: 'Affiliates' }}">Affiliates</router-link> -->

    <!-- <a class="footer-link" href="https://www.indeed.co.uk/viewjob?t=trading+assistant&jk=917cbd12c2234b2f&_ga=2.154254520.1857144260.1541691432-1777259780.1541691432" target="_blank">Job at Geoff Banks</a>
    <a class="footer-link" href="https://geoffbanksracing.com/" target="_blank">Geoff Banks Blog</a> -->
  </div>

<!-- <div v-if="['ios', 'android'].includes($store.state.platform)" class="w-100 text-end"><a href="/#/test_safe_area" class=""> <i class="bi bi-balloon-fill text-white"></i></a></div> -->
</div>
<div v-else>
  <div class="footer container-fluid">
    <div class="row">
      <div class="col-3 d-flex flex-column align-items-center justify-content-center">
        <div class="mb-4 w-75"><img class=" footer-logo" alt="logo" :src="require('@/assets/logo.png')"/></div>
        <div class="w-75 fs-14">© Copyright {{ new Date().getFullYear() }}</div>
      </div>
      <div class="col-9">
        <div class="container h-100">
          <div class="row h-100">
            <div class="col d-flex flex-column justify-content-around">
              <div><a href="/docs/Sports Betting Limited Terms and Conditions.pdf" class="footer-link" target="_blank">Terms and Conditions</a></div>
              <div><a href="/docs/HELP.pdf" class="footer-link" target="_blank">Help</a></div>
            </div>
            <div class="col d-flex flex-column justify-content-around">
              <div><a href="/docs/Responsible Gaming.pdf" class="footer-link" target="_blank">Responsible Gambling Policy</a></div>
              <div><a href="/docs/CONTACT DETAILS.pdf" class="footer-link" target="_blank">Contact Details</a></div>
            </div>
            <div class="col d-flex flex-column justify-content-around">
              <div><a href="/docs/KYC Policy.pdf" class="footer-link" target="_blank">KYC Policy</a></div>
              <div><a href="/docs/KYC Policy.pdf" class="footer-link" target="_blank"></a></div>
            </div>

            <!-- <div class="col d-flex flex-column justify-content-around">
              <div><router-link class="footer-link" :to="{name:'info', params: { infoType: 'About Us' }}">About Us</router-link></div>
              <div><router-link class="footer-link" :to="{name:'info', params: { infoType: 'Contact Details' }}">Contact Details</router-link></div>
              <div><a class="footer-link" href="https://www.indeed.co.uk/viewjob?t=trading+assistant&jk=917cbd12c2234b2f&_ga=2.154254520.1857144260.1541691432-1777259780.1541691432" target="_blank">Job at Geoff Banks</a></div>
              <div><router-link class="footer-link" :to="{name:'info', params: { infoType: 'Terms And Conditions' }}">Terms & Conditions</router-link></div>
            </div>
            <div class="col d-flex flex-column justify-content-around">
              <div><router-link class="footer-link" :to="{name:'info', params: { infoType: 'Betting Rules' }}">Betting Rules</router-link></div>
              <div><router-link class="footer-link" :to="{name:'info', params: { infoType: 'Best Odds Guaranteed' }}">Best Odds Guaranteed</router-link></div>
              <div><router-link class="footer-link" :to="{name:'info', params: { infoType: 'Privacy Policy' }}">Privacy Policy</router-link></div>
              <div><router-link class="footer-link" :to="{name:'info', params: { infoType: 'Cookie Policy' }}">Cookie Policy</router-link></div>
            </div>
            <div class="col d-flex flex-column justify-content-around">
              <div><router-link class="footer-link" :to="{name:'info', params: { infoType: 'ResponsibleGambling' }}">Responsible Gambling</router-link></div>
              <div><router-link class="footer-link" :to="{name:'info', params: { infoType: 'Help' }}">Help</router-link></div>
              <div><a class="footer-link" href="https://geoffbanksracing.com/" target="_blank">Geoff Banks Blog</a></div>
              <div><router-link class="footer-link" :to="{name:'info', params: { infoType: 'Affiliates' }}">Affiliates</router-link></div>
            </div> -->
            <!-- <div v-if="$store.state.platform==='web'" class="col d-flex flex-column justify-content-start">
              <div class="my-2"><a href="https://play.google.com/store/apps/details?id=com.geoff_banks.geoffbanks" target="_blank">
                <img src="@/assets/download_play.svg"/></a>
              </div>
              <div class="my-2"><a href="https://apps.apple.com/gb/app/geoff-banks-betting/id881898186" target="_blank">
                <img src="@/assets/download_apple.svg"/></a>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="container-fluid">
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <!-- <div class="p-3 d-flex justify-content-center align-items-center"><a href="https://www.gamblingcommission.gov.uk/" class="footer_icons footer_icons-desktop" ><img :src="require('@/assets/footer/gambling.png')" alt="gambling"/></a></div>
          <div class="p-3 d-flex justify-content-center align-items-center"><a href="https://www.gamstop.co.uk/" class="footer_icons footer_icons-desktop" ><img :src="require('@/assets/footer/gamstop.png')" alt="gamstop"/></a></div>-->
          <div class="p-3 d-flex justify-content-center align-items-center"><a href="https://www.ibas-uk.com/" class="footer_icons footer_icons-desktop" ><img :src="require('@/assets/footer/ibas.png')" alt="ibas"/></a></div> 
          <div @click="$router.push({name: 'info', params: {infoType: 'over18'}})" class="p-3 d-flex align-items-center justify-content-center cursor-pointer">
            <div class="footer_icons footer_icons-desktop icon_bg" :style="{backgroundImage: 'url(' + require('@/assets/footer/18+.png') + ')', minHeight: '2rem'}"></div>
          </div>
          <div class="p-3 d-flex justify-content-center align-items-center">
            <div id="anj-af7e1519-b1df-4775-9e6f-381b759e4931" data-anj-seal-id="af7e1519-b1df-4775-9e6f-381b759e4931" data-anj-image-size="128" data-anj-image-type="basic-small"></div>
          </div>
        </div>
        <div class="fs-12 me-5 d-flex align-items-center">
          <div class="d-flex mx-3">
            <div class="me-3">
              sportbetsonline.bet  is owned and operated by Sports Betting Limited. Registration number: 15725, registered address: Hamchako, Mutsamudu, The Autonomous Island of Anjouan, Union of Comoros. Contact us at support@sportsbetting.com Sportbetsonline.bet is licensed and regulated by the Government of the Autonomous Island of Anjouan, Union of Comoros and operates under License No. ALSl-192407040-Fl2. Sportbetsonline.bet has passed all regulatory compliance and is legally authorized to conduct gaming operations for any and all games of chance and wagering.
            </div>
            <!-- <div>Responsible Gambling:<br>
              UK - 0808 8020 133 <a class="text-white opacity-50" href="https://www.begambleware.org" target="_blank">www.begambleware.org</a><br>
              IRL - 089 241 5401 <a class="text-white opacity-50" href="https://www.problemgambling.ie" target="_blank">www.problemgambling.ie</a>
            </div> -->
          </div>
          <!-- <div class="mt-3">
            <a class="text-white" href="https://www.gamblingcommission.gov.uk/public-register/business/detail/54095" target="_blank">
              www.gamblingcommission.gov.uk/public-register/business/detail/54095</a>
          </div> -->
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
// import CornerTile from "@/components/CornerTile";

export default {
  name: "MainFooter",
  components: {
    // CornerTile
  },
  mounted(){
    window.anj_af7e1519_b1df_4775_9e6f_381b759e4931.init()
  },
  methods:{
    openLink(url){
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.footer{
  width: 100%;
  background-color: #09080C;
  color: white;
  padding: 2rem;
}
.footer > img {
  width: 45%;
}
hr{
  width: 100%;
  color: #414160;
  margin: 0.5rem;
}
.footer_icons{
  min-height: 3rem;
  min-width: 5rem;
}
.icon_bg{
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.footer_icons > img {
  width: 100%;
  height: auto;
}
.footer_icons-desktop > img{
  max-height: 3rem;
}
.footer-link{
  color: white;
  text-decoration: none;
}
.footer-link:hover{
  color: #ada48f;
}

.mobile-footer-links {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.2rem 1rem;
  
  a {
    text-decoration: underline;
  }
}

.footer-logo {
  max-height: 55px;
}
</style>